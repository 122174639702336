import React from 'react';
import './Flag.styles.scss';
import { FCX } from "@datassential/platform-ui-lib";

const Flag: FCX<{
    name?: string;
    disabled?: boolean;
    component: FCX;
    compact?: boolean;
}> = ({
    name = '',
    disabled = false,
    component: FlagIcon,
    compact = false,
}) => {
    return (
        <div className={`Flag ${disabled ? 'Flag--disabled' : ''} ${compact ? 'Flag--compact' : ''}`} title={name}>
            <FlagIcon/>
        </div>
    );
}

export default Flag;