import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Reports.styles.scss";
import Page from "@components/Page/Page"
import Loader from "@components/Loader/Loader";
import { CSSTransition } from 'react-transition-group';
import apiConfig from "@core/apiConfig";
import { addNotification } from '@actions/notifications.actions';
import ScrollBar from 'react-scrollbars-custom';
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import { useNavigate } from "react-router-dom";
import { IconInfo, IconPDFStroke, IconPpt } from "@icons";
import Icon from "@components/Icon/Icon";
import http from "@core/http";
import { ID, ReportFileExtension } from '@models';
import { ExportTypeEnum } from '@models/ExportTypeEnum';
import useSlidesToGo, { iReport } from '@apiHooks/useSlidesToGo';
import NewTooltip from '@components/NewTooltip/NewTooltip';
import { FCX } from "@datassential/platform-ui-lib";

const Report: FCX<{
    data: iReport;
    downloadPdf: () => void;
    downloadPpt: () => void;
}> = ({
    downloadPdf,
    downloadPpt,
    data,
    testId,
}) => {
    return (
        <div
            className="Report"
            data-testid={testId}
        >
            <div
                className="Report__title"
                data-testid="textReportTitle"
            >
                {data.name.toUpperCase()}
            </div>
            <div className="Report__files">
                <button
                    onClick={downloadPdf}
                    className="Report__file clickable"
                    data-testid="buttonReportDownloadPdf"
                >
                    <Icon className="Report__icon">
                        <IconPDFStroke/>
                    </Icon>
                </button>
                <button
                    onClick={downloadPpt}
                    className="Report__file clickable"
                    data-testid="buttonReportDownloadPpt"
                >
                    <Icon className="Report__icon">
                        <IconPpt/>
                    </Icon>
                </button>
            </div>
            <div className="Report__hover">
                <div
                    className="Report__description"
                    data-testid="textReportDescription"
                >
                    {data.description}
                </div>
            </div>
        </div>
    );
};

const Reports: FC = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const permissions = useSelector((state: any) => currentCountryWithPermissionsSelector(state));
    const countryCode = useSelector((state: any) => state.countries.current);

    const pushNotification = (notification: any) => dispatch(addNotification(notification));

    const { hasReportData } = permissions!;
    if (!hasReportData) navigate('/');

    const { isLoading, data } = useSlidesToGo(countryCode);

    const downloadSummaryReport = (eTypeOfExport: ExportTypeEnum, reportId: ID) => {
        const msgId = Date.now();
        pushNotification({
            text: "Downloading...",
            id: msgId,
        });
        http.downloadFileXHRFromUrl(
            'GET',
            apiConfig.get('export report', { reportId, eTypeOfExport, countryCode })
        ).then(() => {
            pushNotification({
                text: "Download was successful",
                duration: 4000,
                id: msgId,
            });
        });
    };

    const downloadCategoryReport = (eTypeOfExport: ReportFileExtension, reportId: ID) => {
        const msgId = Date.now();
        pushNotification({
            text: "Downloading...",
            id: msgId,
        });
        http.downloadFileXHRFromUrl(
            'GET',
            apiConfig.get('category report', { reportId, eTypeOfExport, countryCode })
        ).then(() => {
            pushNotification({
                text: "Download was successful",
                duration: 4000,
                id: msgId,
            });
        });
    };

    const renderedPlaceholder = (
        <>
            <div
                className="Report"
                style={{ pointerEvents: 'none' }}
            >
                <div
                    className="Report__title"
                    data-testid="textReportsNoData"
                >
                    Coming soon
                </div>
            </div>
            <div/>
        </>
    );

    return (
        <Page isWrapped title="Reports">
            <div className="Reports">
                <div
                    className="Reports__title"
                    data-testid="textReportsTitle"
                >
                    Slides To Go.
                </div>
                <br/>
                <br/>
                <div className="Reports__items">
                    <Loader noBg isShown={isLoading}/>
                    <div className="Reports__columns">
                        <CSSTransition
                            in={!isLoading}
                            classNames="appears3"
                            timeout={2000}
                            unmountOnExit
                        >
                            <div className="Reports__column">
                                <div className="Reports__column-header">
                                    <div
                                        className="Reports__column-title"
                                        data-testid="textReportsCategotyOverviewTitle"
                                    >
                                        Category overviews&nbsp;
                                        <div className="Reports__column-title-icon">
                                            <IconInfo/>
                                            <NewTooltip
                                                position={['below', 'center']}
                                                testId="textReportsCategotyOverviewTooltip"
                                            >
                                                A comprehensive summary of each topical theme’s most popular and fastest
                                                growing items, with detail by segment and region.
                                                <br/>
                                                <br/>
                                                Most reports include
                                                exhibits on long term trends, related Menu Adoption Cycles, innovative
                                                menu examples, and recent limited time offers. Additionally, measures of
                                                consumer awareness and affinity help to complete the picture (included
                                                for subscribers of Consumer Preferences).
                                                <br/>
                                                <br/>
                                                Download these presentation-ready reports in either PDF or PowerPoint
                                                slide format.
                                            </NewTooltip>
                                        </div>
                                    </div>
                                </div>
                                <div className="Reports__table">
                                <ScrollBar>
                                    <div>
                                        {data.categoryReports.map((item, inx) => (
                                            <Report
                                                key={item.id}
                                                data={item}
                                                downloadPdf={() => downloadCategoryReport(ReportFileExtension.Pdf, item.id)}
                                                downloadPpt={() => downloadCategoryReport(ReportFileExtension.Ppt, item.id)}
                                            />
                                        ))}
                                        {data.categoryReports.length === 0 && renderedPlaceholder}
                                    </div>
                                </ScrollBar>
                                </div>
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            in={!isLoading}
                            classNames="appears3"
                            timeout={2000}
                            unmountOnExit
                        >
                            <div className="Reports__column">
                                <div className="Reports__column-header">
                                    <div
                                        className="Reports__column-title"
                                        data-testid="textReportsSummaryTablesTitle"
                                    >
                                        Summary tables&nbsp;
                                        <div className="Reports__column-title-icon">
                                            <IconInfo/>
                                            <NewTooltip
                                                position={['below', 'center']}
                                                testId="textReportsSummaryTablesTooltip"
                                            >
                                                A deep look at menu penetration for each topical theme and its key
                                                components.
                                                <br/>
                                                <br/>
                                                Most reports offer detailed tables by segment, region, and
                                                restaurant type – and include both year-over-year trended data and
                                                growth rates.
                                                <br/>
                                                <br/>
                                                Download these presentation-ready reports in either PDF or PowerPoint
                                                slide format.
                                            </NewTooltip>
                                        </div>
                                    </div>
                                </div>
                                <div className="Reports__table">
                                <ScrollBar>
                                    <div>
                                        {data.summaryReports.map((item, inx) => (
                                            <Report
                                                key={item.id}
                                                data={item}
                                                downloadPdf={() => downloadSummaryReport(ExportTypeEnum.Pdf, item.id)}
                                                downloadPpt={() => downloadSummaryReport(ExportTypeEnum.Ppt, item.id)}
                                            />
                                        ))}
                                        {data.summaryReports.length === 0 && renderedPlaceholder}
                                    </div>
                                </ScrollBar>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
            </div>
        </Page>
    )
};

export default Reports;