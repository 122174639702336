import {
    AppId,
    CountriesSelect, equalInLC,
    FCX,
    Header,
    HeaderLogo,
    ICountriesSelectOption,
    ProductLogo,
} from "@datassential/platform-ui-lib";
import { Link, matchPath, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    countriesSelector,
    currentCountrySelector,
} from "@selectors/countries.selector";
import { useCallback, useMemo } from "react";
import { setCurrent } from "@actions/countries.actions";
import { ICountry } from "@models";
import './PlatformHeader.scss';
import { AVAILABLE_COUNTRIES } from "@reducers/countries.reducer";
import HeaderNavigation from "@containers/PlatformHeader/HeaderNavigation";
import { Routes } from "@routes";

function getCountryNameByCode(code: string): string {
    switch (code.toLowerCase()) {
        case 'usa':
            return 'United States';
        case 'can':
            return 'Canada';
        default:
            return '';
    }
}

const PlatformHeader: FCX = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentCountry = useSelector(currentCountrySelector);
    const countries: ICountry[] = useSelector(countriesSelector);


    const setCurrentCountry = useCallback(
        (countryCode: string) => dispatch(setCurrent(countryCode)),
        [dispatch]
    );

    const countriesOptions = useMemo(
        (): ICountriesSelectOption[] => countries
            .filter(option => AVAILABLE_COUNTRIES.some(
                i => equalInLC(i, option.countryCode)
            ))
            .filter(option => option.hasAccess)
            .map(option => ({
                id: option.countryCode,
                shortName: option.countryCode,
                name: getCountryNameByCode(option.countryCode),
            })),
        [countries]
    );

    const isCountriesDropdownDisabled = useMemo(
        (): boolean => (
            countriesOptions.length === 1
            || !!matchPath(Routes.foodProfile, location.pathname)
            || !!matchPath(Routes.foodProfiles, location.pathname)
            || !!matchPath(Routes.instantCharts, location.pathname)
        ),
        [location.pathname, countriesOptions]
    );


    return (
        <Header
            className="PlatformHeader"
            leftContent={<>
                <Link to="/" data-testid={"buttonHeaderLogo"}>
                    <HeaderLogo>
                        <ProductLogo appId={AppId.MenuTrends}/>
                    </HeaderLogo>
                </Link>
                <CountriesSelect
                    style={{ marginLeft: 10 }}
                    options={countriesOptions}
                    value={currentCountry}
                    setValue={(value) => setCurrentCountry(value as string)}
                    testId={"selectHeaderCountry"}
                    isDisabled={isCountriesDropdownDisabled}
                />
            </>}
            rightContent={<HeaderNavigation/>}
        />
    )
}

export default PlatformHeader;