import React, { useRef, MouseEvent, useMemo } from "react";
import cn from "classnames";
import "./CheckboxInput.scss";
import Flag from "../Flag/Flag";
import { IconArrowRight, IconCheck } from "@icons";
import Icon from "@components/Icon/Icon";
import { FCX } from "@datassential/platform-ui-lib";

const CheckboxInput: FCX<{
    isChecked: boolean;
    isPartialChecked?: boolean;
    onChange: () => void;
    onClickIcon?: (event: MouseEvent) => void;
    labelText?: string;
    labelClass?: string;
    isSmall?: boolean;
    isMedium?: boolean;
    isBold?: boolean;
    isUnderlined?: boolean;
    noIcon?: boolean;
    onClick?: () => void;
    hasArrowIcon?: boolean;
    stopPropagation?: boolean;
    isArrowDown?: boolean;
    isBlueCheck?: boolean;
    disabled?: boolean;
    isRadioBtn?: boolean;
    isCutLabel?: boolean;
    labelIcon?: any;
    showModifierIcon?: boolean;
    modifiers?: string[];
    onMouseEnter?: (event: MouseEvent) => void;
    onMouseLeave?: (event: MouseEvent) => void;
}> = (
    {
        isChecked,
        isPartialChecked = false,
        onChange,
        onClickIcon = () => {},
        labelText = '',
        labelClass = '',
        isSmall = false,
        isMedium = false,
        isBold = false,
        isUnderlined = false,
        noIcon = false,
        onClick,
        hasArrowIcon = false,
        stopPropagation,
        isArrowDown = false,
        isBlueCheck = false,
        disabled = false,
        className = '',
        isRadioBtn = false,
        isCutLabel = false,
        labelIcon = null,
        showModifierIcon = false,
        modifiers = [],
        onMouseEnter = undefined,
        onMouseLeave = undefined,
        testId = undefined,
    }
) => {
    const checkboxRef = useRef(null);

    const handleClickCheckbox = (event: MouseEvent) => {
        stopPropagation && event.stopPropagation();
        onChange();
    };
    const handleIconClick = (event: MouseEvent) => {
        stopPropagation && event.stopPropagation();
        onClick ? onClick() : onChange();
    };

    const successIcon = useMemo(
        () => (
            <Icon
                type="small"
                className={cn(
                    "checkbox-input__icon",
                    isBlueCheck && "is-blue"
                )}
            >
                <IconCheck/>
            </Icon>
        ),
        [isBlueCheck]
    );

    const icon = useMemo(
        () => {
            if (isPartialChecked) {
                return  (
                    <span
                        className={cn(
                            'checkbox-input__fake-check-small',
                            isBlueCheck ? 'checkbox-input__fake-check-small--blue' : 'test',
                        )}
                    />
                );
            }

            if (isChecked) {
                return  successIcon;
            }

            if (isRadioBtn) {
                return  (
                    <span
                        className={cn(
                            'checkbox-input__fake-check-small--radio',
                            isBlueCheck ? 'checkbox-input__fake-check-small--radio-blue' : '',
                        )}
                    />
                );
            }
        },
        [isChecked, isPartialChecked, isRadioBtn, isBlueCheck, successIcon]
    );

    const handleChangeOnIcon = (event: MouseEvent) => {
        handleClickCheckbox(event);
        onClickIcon(event);
    }

    return (
        <div
            className={cn(
                "checkbox-input",
                className,
                isChecked && "checkbox-input--checked",
                isSmall && "checkbox-input--small",
                isMedium && "checkbox-input--medium",
                isBold && "checkbox-input--bold",
                isUnderlined && "checkbox-input--underlined",
                disabled && "checkbox-input--disabled",
                ...modifiers.map(i => `checkbox-input--${i}`)
            )}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-testid={testId}
        >
            <input
                ref={checkboxRef}
                type="checkbox"
                onChange={() => null}
                checked={isChecked}
                className="checkbox-input__real-check"
                disabled={disabled}
                data-testid="inputCheckbox"
            />
            {!noIcon && (
                <button
                    className={cn(
                        'checkbox-input__fake-check',
                        isRadioBtn && 'checkbox-input__fake-check--radio',
                        isChecked && isBlueCheck && 'checkbox-input__fake-check--blue',
                        isChecked && isBlueCheck && isRadioBtn && 'checkbox-input__fake-check--blue-radio',
                    )}
                    onClick={handleChangeOnIcon}
                >
                    {icon}
                </button>
            )}
            {labelIcon && (
                <div className="checkbox-input__label-icon">
                    <Flag compact component={labelIcon}/>
                </div>
            )}
            <button
                onClick={handleClickCheckbox}
                className={cn(
                    "checkbox-input__label",
                    labelClass,
                    isCutLabel && 'checkbox-input__label-cut',
                    showModifierIcon && "checkbox-input__label--has-modifier",
                )}
                data-testid="textCheckboxLabel"
            >
                {labelText}
            </button>
            {hasArrowIcon && (
                <button
                    onClick={handleIconClick}
                    className={`checkbox-input__arrow${isArrowDown ? " checkbox-input__arrow--down" : ""}`}
                    data-testid="buttonCheckboxDropdownToggle"
                >
                    <Icon type="small" className="checkbox-input__arrow-icon">
                        <IconArrowRight/>
                    </Icon>
                </button>
            )}
        </div>
    );
};

export default CheckboxInput;
