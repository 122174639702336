import { useData } from "@hooks/useData";
import { components } from "@apiSchema";
import { ID } from "@datassential/platform-ui-lib";
import { fetchCountryStates } from "@core/api";

type Response = components['schemas']['MenuTrends.Domain.Entities.State'][];

async function getCountryStates(countryCode: ID): Promise<Response> {
    return await fetchCountryStates(countryCode) as Response;
}

export function useCountryStates(countryCode: ID) {
    return useData([], getCountryStates, countryCode);
}