import React, { useEffect, useState, useReducer } from 'react'
import { connect } from 'react-redux';
import './InstantCharts.styles.scss';
import { addNotification } from "@actions/notifications.actions";
import { getInstantCharts } from "@core/api";
import Tiles from '@components/Tiles/Tiles';
import TilesContainer from '@components/TilesContainer/TilesContainer';
import Loader from "@components/Loader/Loader";
import Page from "@components/Page/Page";
import { CSSTransition } from "react-transition-group";

const InstantCharts = (
    {
        countryCode,
    }
) => {
    const [tilesData, dispatch] = useReducer(reducer, initialState);
    const [activeTopTile, setActiveTopTile] = useState(null);
    const [activeSecondTile, setActiveSecondTile] = useState(null);

    const handleItemClick1 = (tileIndex) => {
        setActiveTopTile(tileIndex);

    };
    const handleItemClick2 = (tileIndex) => {
        setActiveSecondTile(tileIndex);
    };

    useEffect(() => {
        getInstantCharts(countryCode).then(res => {
            dispatch(res);
        });
        return () => {};
    }, []);

    let boardTitle = 'Pick a category';
    let tile1Zoom = 1;
    let tile2Zoom = 2;
    let tile3Zoom = 3;

    if (activeTopTile !== null) {
        tile1Zoom = 0;
        tile2Zoom = 1;
        tile3Zoom = 2;
        boardTitle = tilesData[activeTopTile].name;

        if (activeSecondTile !== null) {
            tile2Zoom = 0;
            tile3Zoom = 1;
            boardTitle = tilesData[activeTopTile].subCategories[activeSecondTile].name;
        }
    } else {
        tile1Zoom = 1;
        tile2Zoom = 2;
        tile3Zoom = 3;
        boardTitle = 'Pick a category';
    }

    const handleClickBack = () => {
        if (activeSecondTile !== null) {
            setActiveSecondTile(null);
        } else if (activeTopTile !== null) {
            setActiveTopTile(null);
        }
    };

    return (
        <Page isWrapped title="Instant Charts">
            <div className="InstantCharts">
                <div className="InstantCharts__header">
                    <div
                        className="InstantCharts__board-title"
                        data-testid="textInstantChartsTitle"
                    >
                        {boardTitle}
                    </div>
                    {activeTopTile !== null && (
                        <button
                            className="InstantCharts__btn"
                            onClick={handleClickBack}
                            data-testid="buttonInstantChartsBack"
                        >
                            Back
                        </button>
                    )}
                </div>

                <CSSTransition
                    in={tilesData.length > 0}
                    classNames="appears3"
                    timeout={2000}
                    unmountOnExit
                >
                    <TilesContainer>
                        {tilesData.length > 0 && (
                            <>
                                <Tiles
                                    data={tilesData}
                                    zoomLevel={tile1Zoom}
                                    handleItemClick={handleItemClick1}
                                    zoomIndex={0}
                                />
                                <Tiles
                                    data={activeTopTile !== null ? tilesData[activeTopTile].subCategories : []}
                                    zoomLevel={tile2Zoom}
                                    handleItemClick={handleItemClick2}
                                    zoomIndex={1}
                                />
                                <Tiles
                                    data={(activeSecondTile !== null && activeTopTile !== null) ? tilesData[activeTopTile].subCategories[activeSecondTile].charts : []}
                                    zoomLevel={tile3Zoom}
                                    isItemLink={true}
                                    zoomIndex={2}
                                />
                            </>
                        )}
                    </TilesContainer>
                </CSSTransition>
                <Loader isShown={tilesData.length === 0} noBg/>
            </div>
        </Page>
    )
};

const reducer = (state, action) => action;

const initialState = [];

const mapState = state => {
    return {
        countryCode: state.countries.current,
    };
};

const mapDispatch = dispatch => ({
    addNotification: notification => dispatch(addNotification(notification)),
});

export default connect(mapState, mapDispatch)(InstantCharts)