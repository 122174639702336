import cn from "classnames";
import "./NewTooltip.styles.scss";
import { FCX } from "@datassential/platform-ui-lib";

export type positionType = Array<"left" | "right" | "center" | "above" | "below">;

interface iProps {
    position?: positionType;
}
const NewTooltip: FCX<iProps> = (
    {
        position = ["center", "above"],
        className,
        testId,
        children,
        style,
    }
) => {
    const classNames = cn(
        "NewTooltip",
        className,
        ...position.map(p => `NewTooltip--${p}`),
    );

    return (
        <div
            className={classNames}
            style={style}
            data-testid={testId}
        >
            {children}
        </div>
    );
};

export default NewTooltip;
