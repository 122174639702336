import { Button, FCX, ID, TextInput, } from "@datassential/platform-ui-lib";
import Page from "@components/Page/Page";
import './MenuExamples.scss';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currentCountrySelector } from "@selectors/countries.selector";
import { useCountryStates } from "@apiHooks/useCountryStates";
import StatesSelect from "@pages/MenuExamples/StatesSelect";
import cn from "classnames";

const MenuExamples: FCX = () => {
    const [query, setQuery] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const countryCode = useSelector(currentCountrySelector);
    const [isChainsEnabled, setIsChainsEnabled] = useState(true);
    const [selectedStatesIds, setSelectedStatesIds] = useState<ID[]>([]);
    const { data: states } = useCountryStates(countryCode);
    const submitSearch = () => {
        setIsSubmitted(!isSubmitted);
    };

    useEffect(() => {
        setSelectedStatesIds(states.map(state => state.id));
    }, [states]);

    return (
        <Page isWrapped title="Menu Examples">
            <div className="MenuExamples">
                <div
                    className="MenuExamples__title"
                    data-testid="textMenuExamplesTitle"
                >
                    Menu Examples
                </div>
                <div className={cn("MenuExamples__content", !isSubmitted && 'expanded')}>
                    <div className="MenuExamples__row">
                        <div
                            className="MenuExamples__sub-title"
                            data-testid="textMenuExamplesHeaderTitle"
                        >
                            Browse over 20 million dishes from more than 200,000 menus
                        </div>
                    </div>
                    <div className="MenuExamples__row">
                        <TextInput
                            className="MenuExamples__search"
                            value={query}
                            setValue={setQuery}
                            placeholder="Search anything..."
                            testId="inputMenuExamplesMainSearch"
                        />
                        <Button
                            onClick={submitSearch}
                            className={'MenuExamples__search-button'}
                            testId="buttonMenuExamplesMainSearchSubmit"
                        >
                            Search
                        </Button>
                    </div>
                    <StatesSelect
                        isChainsEnabled={isChainsEnabled}
                        setIsChainsEnabled={setIsChainsEnabled}
                        value={selectedStatesIds}
                        setValue={setSelectedStatesIds}
                        options={states}
                    />
                </div>
            </div>
        </Page>
    );
};

export default MenuExamples;