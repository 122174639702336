import {
    cssX,
    Dropdown, FCX, getRem,
    IconDropdownArrowUp,
    ID,
    IDropdownItem,
    Navigation,
    useOutsideClick, usePlatformFeatureFlag
} from "@datassential/platform-ui-lib";
import { useMemo, useRef, useState } from "react";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { Routes } from "@routes";
import cn from "classnames";
import { ICountryPermissions } from "@models";
import { useSelector } from "react-redux";
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import HeaderSearch from "@containers/PlatformHeader/HeaderSearch";
import { FF_CULINARY_MASTER } from "@constants/general";

const SubNavItem: FCX<{
    path: string;
    testId?: string;
}> = ({
    path,
    testId,
    children,
}) => {
    return (
        <NavLink
            to={path}
            data-testid={testId}
            className={cn(
                cssX('Dropdown__item'),
                'is-tall',
                cssX("PlatformHeader__nav-sub-item")
            )}
        >
            {children}
        </NavLink>
    )
}

const HeaderNavigation = () => {
    const location = useLocation();
    const currentCountryPermissions: ICountryPermissions | undefined = useSelector(currentCountryWithPermissionsSelector);

    const analyzeRef = useRef(null);
    const [isAnalyzeOpened, setIsAnalyzeOpened] = useState(false);
    const isCulinaryMasterFeatureEnabled = usePlatformFeatureFlag(FF_CULINARY_MASTER);

    useOutsideClick({
        ref: analyzeRef,
        callback: () => setIsAnalyzeOpened(false),
    });

    const analyzeLinks = useMemo(
        () => {
            const result: Array<{
                id: ID;
                title: string;
                path: string;
                testId?: string;
            }> = [];

            if (currentCountryPermissions?.hasInsertChart) {
                result.push({
                    id: 'Instant Charts',
                    title: 'Instant Charts',
                    path: Routes.instantCharts,
                    testId: 'buttonHeaderAnalyzeInstantCharts',
                });
            }

            if (isCulinaryMasterFeatureEnabled) {
                result.push({
                    id: 'Menu Examples',
                    title: 'Menu Examples',
                    path: Routes.menuExamples,
                    testId: 'buttonHeaderAnalyzeMenuExamples',
                });
            }

            return result;
        },
        [currentCountryPermissions, isCulinaryMasterFeatureEnabled]
    );

    const analyzeItems = useMemo(
        (): IDropdownItem[] => analyzeLinks.map(item => ({
            id: item.id,
            content: (
                <SubNavItem path={item.path} testId={item.testId}>
                    {item.title}
                </SubNavItem>
            ),
        })),
        [analyzeLinks]
    );

    const isAnalyseActive = useMemo(
        () => analyzeLinks.some(item => matchPath(item.path, location.pathname)),
        [analyzeLinks, location.pathname],
    );

    const navigationItems = useMemo(
        () => {
            const result = [
                {
                    id: 'Top Trends',
                    content: (
                        <NavLink
                            to={Routes.toptrends}
                            data-testid={"buttonHeaderTopTrends"}
                            className={"PlatformHeader__nav-item"}
                        >
                            Top Trends
                        </NavLink>
                    ),
                },
            ];

            if (currentCountryPermissions?.hasFoodProfile) {
                result.push({
                    id: 'Profiles',
                    content: (
                        <NavLink
                            to={Routes.foodProfiles}
                            data-testid={"buttonHeaderProfiles"}
                            className={"PlatformHeader__nav-item"}
                        >
                            Profiles
                        </NavLink>
                    ),
                });
            }

            if (currentCountryPermissions?.hasRtuReport) {
                result.push({
                    id: 'reports',
                    content: (
                        <NavLink
                            to={Routes.reports}
                            data-testid={"buttonHeaderReports"}
                            className={"PlatformHeader__nav-item"}
                        >
                            Reports
                        </NavLink>
                    ),
                });
            }

            if (analyzeItems.length > 0) {
                result.push(
                    {
                        id: 'analyze',
                        content: (
                            <div
                                className={cn(
                                    "PlatformHeader__nav-item",
                                    isAnalyseActive && 'active-accessible',
                                    isAnalyzeOpened && 'hover',
                                )}
                                data-testid={"selectHeaderAnalyze"}
                                onClick={() => {
                                    setIsAnalyzeOpened(!isAnalyzeOpened)
                                }}
                                onMouseEnter={() => setIsAnalyzeOpened(true)}
                                data-tooltip-id="HeaderAnalyze"
                                ref={analyzeRef}
                            >
                                Analyze
                                <IconDropdownArrowUp
                                    className="PlatformHeader__nav-icon"
                                    style={{ transform: `rotate(${isAnalyzeOpened ? 0 : 180}deg)` }}
                                />
                                <Dropdown
                                    isOpened={isAnalyzeOpened}
                                    id="HeaderAnalyze"
                                    place={'bottom-end'}
                                    offset={0}
                                    columns={[
                                        {
                                            id: 'analyze',
                                            items: analyzeItems,
                                        }
                                    ]}
                                />
                            </div>
                        ),
                    },
                );
            }

            result.push(
                {
                    id: 'search',
                    content: <HeaderSearch/>,
                }
            );

            return result;
        },
        [currentCountryPermissions, isAnalyseActive, analyzeItems, isAnalyzeOpened]
    );

    return (
        <Navigation data={navigationItems}/>
    );
}

export default HeaderNavigation;