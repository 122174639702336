import cn from "classnames";
import "./CellNew.styles.scss";
import { FCX } from "@datassential/platform-ui-lib";

const CellNew: FCX<{
    modifiers?: string[];
}> = ({
    className = '',
    modifiers = [],
    children,
    testId,
    style,
}) => {
    const classNames = cn(
        "CellNew",
        modifiers.map(m => `CellNew--${m}`),
        className
    );

    return (
        <div
            className={classNames}
            style={style}
            data-testid={testId}
        >
            {children}
        </div>
    )
}

export default CellNew;